/* eslint-disable react/no-danger */
import React from 'react';
import {graphql} from 'gatsby';

import {Layout, SEO} from '../components';

interface MarkdownTemplateProps {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        path: string;
      };
      html: string;
    };
  };
}

export const MarkdownTemplate = ({data}: MarkdownTemplateProps): JSX.Element => {
  const {markdownRemark} = data;
  const {
    frontmatter: {title, path},
    html,
  } = markdownRemark;
  return (
    <Layout>
      <SEO title={title} pathname={path} />
      <div className="my-2 max-w-7xl mx-auto markdown">
        <div dangerouslySetInnerHTML={{__html: html}} />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path}}) {
      html
      frontmatter {
        title
        path
      }
    }
  }
`;

export default MarkdownTemplate;
